import * as React from "react";
import { useState } from "react";

export interface TextAreaProps {
  name?: string;
  id?: string;
  placeholder: string;
  className?: string;
  initlaValue?: string;
  onChange: (value: string) => void;
  hideLabel?: boolean;
  label?: string;
  rows?: number;
  cols?: number;
}

const TextArea: React.FC<TextAreaProps> = ({
  name,
  id,
  placeholder,
  className,
  initlaValue = "",
  onChange,
  hideLabel = true,
  label = "input",
  rows,
  cols,
}) => {
  const [inputValue, setInputValue] = useState(initlaValue);

  return (
    <div className={`flex flex-col space-y-2  ${className}`}>
      {!hideLabel && (
        <label className={"font-bold"} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={"relative"}>
        <textarea
          rows={rows}
          cols={cols}
          id={id}
          name={name}
          required
          value={inputValue}
          onChange={ev => {
            setInputValue(ev.target.value);
            onChange(ev.target.value);
          }}
          placeholder={placeholder}
          className={`py-2 rounded-md bg-white
          px-5 w-full
          border border-dark-100
          ring-dark-100/50
          focus:outline-none focus:ring-2 `}
        />
      </div>
    </div>
  );
};

export default TextArea;
