import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import { sendContactMessage } from "../lib/functions";
import { useState } from "react";

// components
import Icon from "../components/icon";
import IconButton from "../components/iconbutton";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Input from "../components/custom-input";
import TextArea from "../components/custom-textarea";

// images
import ContactImage from "../images/contact.png";
import ChatContactImage from "../images/footer-chat.png";

// types & interfaces
import type { ContactFormValues, PageProps, SEO } from "../lib/types";

interface ContactPageProps {
  SEO: SEO;
  title: string;
  description: string;
}

const ContactPage: React.FC<PageProps<ContactPageProps>> = ({
  data: {
    page: { SEO, title, description },
  },
  location: { pathname },
}) => (
  <>
    <Layout active={"#about"}>
      {/* Google ReCaptcha Script */}
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" defer />
      </Helmet>

      <Seo {...SEO} pathname={pathname} />

      <main className="mt-40 flex flex-col items-start space-y-10 px-5 xl:px-40 ">
        {/* Title section */}
        <TitleSection title={title} description={description} />

        <section className="grid gap-8 pb-52 w-full lg:grid-cols-2">
          {/* Information Panel */}
          <div className={"order-last lg:order-first"}>
            <InformationPanel />
          </div>

          {/* The contact form */}
          <ContactForm />
        </section>
      </main>
    </Layout>
  </>
);

export default ContactPage;

const TitleSection: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => (
  <section className="flex space-x-8">
    <img
      src={ContactImage}
      alt="Contact"
      className="object-contain object-top"
    />
    <div className="flex flex-col space-y-2 md:space-y-4">
      <h1 className="text-2xl md:text-3xl font-bold">{title}</h1>
      <p className={"text-sm md:text-base"}>{description}</p>
    </div>
  </section>
);

const InformationPanel = () => (
  <div className="pt-5 bg-light-100 flex flex-col justify-between space-y-15">
    <div className="flex space-x-2 px-10">
      <div className={"hidden sm:block"}>
        <Icon
          icon="phone"
          className="text-secondary-50 transform rotate-12 h-30"
        />
      </div>

      <div className="flex flex-col space-y-2 lg:space-y-3">
        <h2 className="text-xl lg:text-2xl">Besoin d'aide ? Discutons !</h2>
        <h3 className="text-lg lg:text-xl">Appelez-nous au : </h3>
        <div>
          <span className="font-bold bg-primary-100 text-white rounded-xl px-2 py-1 cursor-pointer text-sm lg:text-base">
            <a href="tel:+33970170420">09 70 17 04 20</a>
          </span>
          &nbsp;&nbsp;
          <small className={"text-xs"}>(prix d'un appel local)</small>
        </div>

        <p className={"text-sm"}>
          Un conseiller financier à votre écoute du lundi au vendredi de 9h30 à
          18h30.
        </p>

        <div className="flex items-center justify-center w-full">
          <div className="flex justify-between w-full lg:max-w-[80%]">
            <IconButton
              icon="twitter"
              href="https://twitter.com/monemprunt"
              iconClassName="!h-5 !w-5"
              className="p-1"
              external
            />
            <IconButton
              icon="facebook"
              href="https://www.facebook.com/monempruntcom/"
              iconClassName="!h-5 !w-5"
              className="p-1"
              external
            />
            <IconButton
              icon="youtube"
              href="https://www.youtube.com/channel/UChQ7LMHG4e-cNhz8-sQ1yQQ"
              iconClassName="!h-5 !w-5"
              className="p-1"
              external
            />
            <IconButton
              icon="linkedin"
              href="https://www.linkedin.com/company/11205083/"
              iconClassName="!h-5 !w-5"
              className="p-1"
              external
            />
          </div>
        </div>
      </div>
    </div>
    <div className="bg-secondary-100 flex flex-col items-center text-white relative py-2">
      <img
        src={ChatContactImage}
        alt="Chat"
        className="absolute bottom-0 left-0 h-20 md:h-30"
      />
      <div className="flex flex-col">
        <div className="font-bold text-base md:text-xl">Chattez avec nous</div>
        <p className="font-light text-sm md:text-base">
          Besoin d'aide ? Discutons !
        </p>
      </div>
    </div>
  </div>
);

interface AlertMessage {
  type: "error" | "success";
  message: string;
}

const ContactForm = () => {
  let fields: ContactFormValues = {
    nom: "",
    prenom: "",
    mel: "",
    tel: "",
    message: "",
  };
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (name: string, value: string) => {
    setAlertMessage(null);
    fields = { ...fields, [name]: value };
  };

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    // Get the captcha response value then submit the form
    const captcha: string = (
      document.querySelector(".g-recaptcha-response") as HTMLTextAreaElement
    )?.value;

    if (captcha && captcha.length > 0) {
      sendContactMessage({ ...fields, captcha })
        .then(({ error }) => {
          setAlertMessage({
            type: error ? "error" : "success",
            message: error ?? "Votre contact a bien été envoyé",
          });
        })
        .finally(() => {
          setLoading(false);
        });
      setLoading(true);
    } else {
      setAlertMessage({
        type: "error",
        message: "Veuillez valider le captcha",
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={"grid gap-2 w-full lg:gap-4 lg:grid-cols-2"}
    >
      <h2 className="text-xl md:text-2xl lg:col-span-2">
        Envoyez-nous un e-mail
      </h2>

      <div className="lg:col-span-2">
        {alertMessage && <Alert {...alertMessage} />}
      </div>

      <Input
        type="text"
        name="lastName"
        placeholder="Votre nom"
        onChange={val => {
          handleFieldChange("nom", val);
        }}
      />

      <Input
        type="text"
        name="prenom"
        placeholder="Votre prénom"
        onChange={val => {
          handleFieldChange("prenom", val);
        }}
      />
      <Input
        type="email"
        name="mel"
        placeholder="Votre addresse e-mail"
        onChange={val => {
          handleFieldChange("mel", val);
        }}
      />
      <Input
        type="tel"
        name="tel"
        placeholder="Votre téléphone"
        onChange={val => {
          handleFieldChange("tel", val);
        }}
      />
      <TextArea
        className={"lg:col-span-2"}
        name="message"
        rows={5}
        placeholder="Votre message"
        onChange={val => {
          handleFieldChange("message", val);
        }}
      />
      {/* Captcha Button */}
      <div
        className="g-recaptcha lg:col-span-2"
        data-sitekey="6Ld5DccUAAAAAMypEjfjR5rmVNIJAQh1ylTeYtO5"
      />

      <button
        type="submit"
        disabled={loading}
        className={`${
          !loading ? "bg-primary-100" : "bg-primary-50 cursor-not-allowed"
        } text-white hover:shadow-primary rounded-lg font-bold px-2 py-3 text-base
        lg:col-span-2`}
      >
        {loading ? "Envoi en cours..." : "Envoyer"}
      </button>
    </form>
  );
};

const Alert: React.FC<AlertMessage> = ({ type, message }) => {
  return (
    <div
      className={`rounded-md w-full p-4 border ${
        type === "success"
          ? "bg-green-300/50 border-green-600 text-green-600"
          : "bg-red-300/50 border-red-600 text-red-600"
      }`}
    >
      {message}
    </div>
  );
};

export const query = graphql`
  query pageContact {
    page: strapiPageContact {
      title
      description
      SEO {
        description
        slug
        title
      }
    }
  }
`;
