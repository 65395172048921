import { Link } from "gatsby";
import * as React from "react";
import { ButtonTypes } from "./button";
import Icon, { Icons } from "./icon";

export interface IconButtonProps {
  icon: typeof Icons[number];
  className?: string;
  iconClassName?: string;
  href?: string;
  type?: typeof ButtonTypes[number] | "light";
  external?: boolean;
  target?: "_self" | "_blank" | "_parent" | "_top";
  ariaLabel?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon = "lock",
  type = "secondary",
  className = "",
  iconClassName = "",
  href = "#",
  external = false,
  target = "_blank",
  ariaLabel = icon,
}) => {
  const Component = (
    props: Omit<IconButtonProps, "icon"> & {
      to: string;
      children: React.ReactNode;
    }
  ) =>
    external ? (
      <a aria-label={ariaLabel} {...props} href={props.to} target={target} />
    ) : (
      <Link aria-label={ariaLabel} {...props} />
    );
  return (
    <div className={`${className} inline-block`}>
      <Component
        className={`${
          icon !== "user" ? "px-3 py-3" : "px-1 py-1"
        } flex justify-center items-center
          rounded-full font-bold ${
            type === "cta"
              ? "bg-primary-100 text-white hover:shadow-primary"
              : type === "secondary"
              ? "bg-secondary-100 text-white  hover:shadow-secondary"
              : " bg-light-100 "
          }`}
        to={href}
      >
        <Icon
          icon={icon}
          className={`${iconClassName} ${
            type === "light" && "text-secondary-100 "
          } ${icon !== "user" ? "h-5" : "h-8"} `}
        />
      </Component>
    </div>
  );
};

export default IconButton;
